<template>
  <div class="visit-summary-wrapper">
    <v-card class="visit-summary visit-summary_patient">
      <VisitSummaryHeader />
      <div class="visit-summary_title-line">
        <span>{{ $t("visits.summary.patientInformation") }}</span>
      </div>
      <VisitSummaryPatientInfo />
      <div class="visit-summary_title-line">
        <span>{{ $t("visits.summary.encounterNotes") }}</span>
      </div>
      <div v-if="encounterNotes" class="visit-summary_encounter-notes">
        <EncounterNote
          :text="translateChiefComplaint(encounterNotes.chiefComplaint)"
          :title="$t('visits.chiefComplaint')"
        />
        <EncounterNote :text="encounterNotes.dx" :title="$t('visits.summary.dx')" />
        <EncounterNote :text="encounterNotes.assessment" :title="$t('visits.notes.plan')" />
        <EncounterNote :text="encounterNotes.plan" :title="$t('visits.notes.plan')" />
      </div>
      <v-divider></v-divider>
      <VisitSummaryLetter />
    </v-card>
  </div>
</template>
<script>
import { mapState } from "pinia";

import { useVisitNoteStore } from "@/pinia-store/visitNote";

import EncounterNote from "./EncounterNote";
import VisitSummaryHeader from "./Header.vue";
import VisitSummaryLetter from "./Letter.vue";
import VisitSummaryPatientInfo from "./PatientInfo.vue";

export default {
  name: "PatientSummary",
  components: {
    VisitSummaryHeader,
    VisitSummaryPatientInfo,
    VisitSummaryLetter,
    EncounterNote,
  },
  computed: {
    ...mapState(useVisitNoteStore, ["medicalInfo", "encounterNotes", "checkout"]),
  },
  methods: {
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
};
</script>
