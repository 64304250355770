<template>
  <div class="visit-summary-container">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-if="!loading">
      <span class="page-header">{{ $t("visits.description.title") }}</span>
      <v-row v-if="!error">
        <v-col class="visit-summary-col" cols="12" md="6">
          <VisitSummary />
        </v-col>
        <v-col cols="12" md="6">
          <VisitDescription />
        </v-col>
      </v-row>
      <div v-else class="page-not-found">
        <img src="../../../assets/paper-lists.svg" />
        <h2 class="heading-2">{{ $t("visits.summary.errorTitle") }}</h2>
        <h3 class="sub-heading-1">
          {{ $t("visits.summary.errorText") }}
        </h3>
        <v-btn color="primary" outlined @click="redirectVisitCenter">{{ $t("visits.expire.button") }}</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapActions } from "pinia";

import VisitDescription from "@/components/VisitDescription/Patient";
import VisitSummary from "@/components/VisitSummary/PatientSummary";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "PatientVisitSummaryPage",
  components: {
    VisitSummary,
    VisitDescription,
  },
  props: {
    encounter: Object,
  },
  data: () => ({
    error: false,
    loading: true,
  }),
  computed: {
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    visitDate() {
      return DateTime.fromISO(this.currentEncounter.start).toFormat("d MMMM yyyy");
    },
    duration() {
      const start = DateTime.fromISO(this.currentEncounter.start).toFormat("H:mm");
      const end = DateTime.fromISO(this.currentEncounter.end).toFormat("H:mm");
      return `${start} - ${end}`;
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["getVisitSummary"]),
    ...mapActions(useEncountersStore, ["getCurrentEncounterById"]),
    ...mapActions(useVisitNoteStore, ["setAppointmentId", "setEncounterId"]),
    async redirectVisitCenter() {
      await this.$router.push("/patient/visits/upcoming");
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const encounter = await this.getCurrentEncounterById(this.encounter.id);
      if (encounter.status === EncounterStatusEnum.finished) {
        await this.$router.push({ name: routesEnum.patientVisitNote });
        return;
      }
      const appointmentId = this.currentEncounter && this.currentEncounter.appointmentId;
      this.setAppointmentId(appointmentId);
      this.setEncounterId(this.encounter.id);
      await this.getVisitSummary();
    } catch (err) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.visit-summary-container {
  @media (max-width: 767.98px) {
    margin: 0 20px;
  }

  .page-header {
    display: none;
    font-weight: 500;
    font-size: 26px;
    padding: 20px;
    margin: 0 -20px;
    background: $white;
    box-shadow: 0 3px 8px rgba(238, 239, 247, 0.32);
    text-align: left;
    margin-bottom: 15px;

    @media (max-width: 767.98px) {
      display: block;
    }
  }
}

.header {
  box-shadow: 0px 1px 0px $primarylight;
  border-bottom: thin solid $white-light-1;
  height: 70px;
  width: 100%;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid rgba(175, 169, 175, 0.2);

  &-select {
    margin: 0 20px;
    display: flex;
    align-items: center;
  }
}

.header-bordered {
  display: flex;
  width: 200px;
  align-items: center;
  border-left: 1px solid $primarylight;
  padding-left: 5px;

  p {
    margin: 0 5px;
  }
}
</style>
