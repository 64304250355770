<template>
  <v-card class="patient-description-wrapper" outlined>
    <v-card v-if="$vuetify.breakpoint.xsOnly" class="header" height="45" tile>
      <h3>
        {{ $t("visits.description.title") }}
      </h3>
    </v-card>
    <v-card v-if="checkout && checkout.price" class="patient-info" tile>
      <v-row class="patient-info-row">
        <v-col class="patient-info-left" cols="6">
          <img src="../../assets/patient-description/money.svg" />
          <v-card-text class="patient-info-left-text">{{ $t("visits.description.youHavePaid") }}</v-card-text>
        </v-col>
        <v-col class="patient-info-right" cols="6">
          <h2>{{ checkout.price | rawPrice }}</h2>
          <div class="pay-date">
            <span>{{ $t("visits.payment.payDate") }}: {{ visitDate }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="patient-info-bottom">
      <v-list-item class="px-2 avatar-section">
        <v-list-item-avatar size="46">
          <Avatar :userId="practitionerId" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ currentEncounter.practitioner.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="patient-info_contacts">
        <span v-if="practitionerEmail" class="patient-info_email">
          {{ practitionerEmail }}
        </span>
        <template v-if="practitionerPhoneNumber">
          <v-icon> mdi-circle-small</v-icon>
          <span class="patient-info_phone">
            {{ practitionerPhoneNumber }}
          </span>
        </template>
      </div>
      <span class="patient-info-chief">
        <img class="d-visit-desc_tick-icon" src="../../assets/icons/rounded-sick.svg" />
        <span class="subtitle">{{ $t("visits.chiefComplaint") }} </span>
      </span>
      <v-card-text class="patient-info_reason">
        {{ translateChiefComplaint(encounterNotes.chiefComplaint) }}
      </v-card-text>
      <v-list-item v-if="visitDescription.complaintDescription" class="px-2 avatar-section">
        <v-list-item-content>
          <v-list-item-subtitle>Visit Reason Note</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text v-if="visitDescription.complaintDescription">
        {{ visitDescription.complaintDescription }}
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  name: "PatientVisitDescription",
  components: {
    Avatar,
  },
  computed: {
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    ...mapState(useVisitNoteStore, [
      "encounterNotes",
      "visitDescription",
      "practitionerEmail",
      "practitionerPhoneNumber",
      "practitionerId",
      "checkout",
    ]),

    visitDate() {
      return this.currentEncounter?.start ? DateTime.fromISO(this.currentEncounter?.start).toFormat("d/M/yyyy") : "";
    },
  },
  methods: {
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
  filters: {
    rawPrice(price) {
      return `$${parseInt(price, 10) / 100}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.patient-description-wrapper {
  max-width: 400px;

  @media (max-width: 767.98px) {
    max-width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $primaryblack;
  padding-left: 20px;
  border-radius: 2px 2px 0 0;
}

.patient-info {
  padding: 0;
  height: 110px;
  background: url("../../assets/patient-description/background.svg");
  background-repeat: no-repeat;
  background-size: 100%;

  &-chief {
    padding: 0 8px;
  }

  &-bottom {
    padding: 20px;

    .v-card__text {
      padding: 8px 8px 16px 8px;
      color: $primaryblack !important;
    }
  }

  &_email {
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: #2177e0;
    padding: 0;
  }

  &_phone {
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: $primarylight3;
    padding: 0;
  }

  &_contacts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
  }

  &-row {
    height: 100%;
    margin: 0;
  }

  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: end;

    h2 {
      padding-right: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 27px;
      line-height: 33px;
      text-align: right;
      color: $white;
    }

    .pay-date {
      padding-right: 8px;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      opacity: 0.56;
      text-align: right;
    }

    &-text {
      padding-top: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      mix-blend-mode: normal;
      opacity: 0.4;
    }
  }

  &-left {
    display: flex;
    padding-left: 16px;

    &-text {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;

      color: $white;
    }
  }
}

.avatar-section {
  .v-list-item__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: $primaryblack2;
  }

  .v-list-item__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: $primaryblack2;

    opacity: 0.4;
  }
}
</style>
